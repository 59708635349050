import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/1.jpg";
import IMG2 from "../../assets/2.jpg";
import IMG3 from "../../assets/3.jpg";
import IMG4 from "../../assets/4.jpg";
import IMG5 from "../../assets/5.jpg";
import IMG6 from "../../assets/6.jpg";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Jharkhand Mega Seminar",
  },
  {
    id: 2,
    image: IMG2,
    title: "Felicitation",
  },
  {
    id: 3,
    image: IMG3,
    title: "Verna Car at the age of 22",
  },
  {
    id: 4,
    image: IMG4,
    title: "Youth icon award of Hazaribag at the age of 21",
  },
  {
    id: 5,
    image: IMG5,
    title: "Youth icon award of jharkhand at the age of 22",
  },
  {
    id: 6,
    image: IMG6,
    title: "Bike achieve at the age of 19",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent</h5>
      <h2>Achievements</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
              </div>

            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
