import React from "react";
import "./footer.css";
import { FaFacebook, FaInstagram} from "react-icons/fa";


const Footer = () => {
  return (
    <footer>
      {/* eslint-disable-next-line */}
      <a href="#" className="footer__logo">
        Pankaj Kohli
      </a>
      <ul className="permalinks">
        <li>
          {/* eslint-disable-next-line */}
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#portfolio">Achievement</a>
        </li>
        <li>
          <a href="#testimonials">Destination</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="footer__socials">
        <a href="https://www.facebook.com/profile.php?id=100005196137472">
          <FaFacebook />
        </a>
        <a href="https://www.instagram.com/__pankaj_kohli_/">
          <FaInstagram />
        </a>
        {/* <a href="https://wa.me/qr/3ULT3YH6OEQ5A1">
          <FaWhatsapp />
        </a> */}
      </div>
      <div className="footer__copyright">
        <small>&copy; Pankaj Kohli. All rights reserved</small> <br/>
        <small><a href="https://wa.me/qr/CY6K6OK2MHZFF1 ">Developed By Firoz & Team</a></small>
        
      </div>
    </footer>
  );
};

export default Footer;
