import React from "react";

import { FaFacebook,FaInstagram, FaWhatsapp } from "react-icons/fa";


const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://wa.me/qr/3ULT3YH6OEQ5A1">
        <FaWhatsapp/>
      </a>
      <a href="https://www.facebook.com/profile.php?id=100005196137472">
        <FaFacebook />
      </a>
      <a href="https://www.instagram.com/__pankaj_kohli_/">
        <FaInstagram />
      </a>
    </div>
  );
};

export default HeaderSocials;
