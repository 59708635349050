import React from "react";
import "./about.css";
import profile_picture from "../../assets/profile3.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
// import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={profile_picture} alt="About Ademir" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>
                Work More than 3 years in Multi-level Marketing & create more than 2 Crores turnover. <br /> 6 Months work in NABARD
              </small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Education</h5>
              <small>
                B.Sc. Agriculture from AISECT University,  <br /> MBA IN Agriculture from  Birsa Agriculture University, Ranchi (Jh)
              </small>
            </article>
          </div>
          <p>
            I am pankaj kumar (aka <b>Pankaj Kohli</b>) belongs from Hazaribagh jharkhand,

            born in lower middle class with a lots of dream at 31st december 1998 & i have seen so many struggle in childhood life & done always good in studies ,always focus on carrier along with dedication  i was superb towards goals & future

            i have done 10th from ssvm ptps
            & 12th from science extreme from inter science college.
            <p>
            I have done my Graduation in agriculture from Aisect university & got scored 83%.
            currently pursuing MBA in Agriculture from Birsa Agriculture University Ranchi Jharkhand.
            </p>
            
          </p>
          <p>
            In recent years, i have trained more than 40000 peoples in marketing field. Help more than 40 bikes achievers youth in india & more than 16 cars achievers in india.
          </p>
          <p>
            Work more than 3years in multi level marketing & done more than 2cr turnover
            after that joined job in NABARD & work 6 months
            after that i'm currently working in forex market.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
