import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/thailand.jpg";
import AVTR2 from "../../assets/goa.jpg";
import AVTR3 from "../../assets/meghalaya.jpg";
import AVTR4 from "../../assets/thailand.jpg";
import AVTR5 from "../../assets/goa.jpg";
import AVTR6 from "../../assets/thailand.jpg";
import AVTR7 from "../../assets/meghalaya.jpg";
import AVTR8 from "../../assets/thailand.jpg";
import AVTR9 from "../../assets/goa.jpg";

// import required modules
import { Pagination } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    avatar: AVTR1,
    name: "Thailand",
    review:
      "Thailand is not just a destination, it's an experience that stays with you forever. In Thailand, every moment is a new page in the story of your adventure.",
  },
  {
    avatar: AVTR2,
    name: "Goa",
    review:
      " In Goa, time is measured by the rhythm of the waves and the colors of the sunset.",
  },
  {
    avatar: AVTR3,
    name: "Meghalaya",
    review:
      " The living root bridges of Meghalaya are not just architectural marvels; they are nature's embrace reaching across valleys.",
  },
  {
    avatar: AVTR4,
    name: "Mumbai",
    review:
      "In the rhythm of Mumbai, every local train is a heartbeat, every dabbawala a messenger of dedication, and every street vendor a symbol of resilience.",
  },
  {
    avatar: AVTR5,
    name: "Delhi",
    review:
      " Delhi, where the past and the present walk hand in hand, creating a mosaic of memories in the tapestry of time.",
  },
  {
    avatar: AVTR6,
    name: "Kolkata",
    review:
      "Kolkata, where Tagore's verses linger in the air, and the City of Joy lives up to its name with every smile and nod.",
  },
  {
    avatar: AVTR7,
    name: "Odisha",
    review:
      "Odisha is a land where history whispers through ancient temples, and nature sings in the rhythm of its coasts and forests.",
  },
  {
    avatar: AVTR8,
    name: "Kerala",
    review:
      "In the backwaters of Kerala, every ripple tells a story, and every palm tree sways to the rhythm of tranquility.",
  },
  {
    avatar: AVTR9,
    name: "Bihar",
    review:
      "In the heart of Bihar, the Ganga flows like a lifeline, nurturing the land and carrying the stories of a thousand years.",
  },
];

// https://swiperjs.com/demos#pagination-dynamic

const Testimonials = () => {
  return (
    <section id="testimonials">
      {/* <h5>Reviews from coworkers</h5> */}
      <h2>Visited Places</h2>
      <Swiper
        className="container testiomonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="coworker-avatar">
                <img src={avatar} alt="Coworker" />
              </div>
              <h5 className="coworker__name">{name}</h5>
              <small className="coworker__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
